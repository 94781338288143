import React from 'react';

import { Page } from '../Page/Page';
import { VideoElement } from './VideoElement/VideoElement';

import styles from './Music.module.scss';

const videoId = 'RqM-XzFoFzY';

export const Music = () => {
  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.videoContainer}>
          <VideoElement videoId={videoId} />
        </div>
      </div>
    </Page>
  );
};
