import { useState } from 'react';

export const useBooleanState = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue);

  const setTrue = () => setValue(true);
  const setFalse = () => setValue(false);
  const toggle = () => setValue(!value);

  return { value, setValue, setTrue, setFalse, toggle };
};
