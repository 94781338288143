import React from "react";

import { useBooleanState } from "../../../util/hooks";

import styles from "./VideoElement.module.scss";

interface VideoElementProps {
  videoId: string;
}

export const VideoElement = ({ videoId }: VideoElementProps) => {
  const { value: clicked, setTrue: setClicked } = useBooleanState(false);

  const content = clicked ? (
    <iframe
      title={videoId}
      src={getVideoUrl(videoId)}
      frameBorder="0"
      allowFullScreen
    ></iframe>
  ) : (
    <div onClick={setClicked}>
      <img src={getThumbnailUrl(videoId)} alt={""} />
      <div className={styles.playButton} />
    </div>
  );
  return <div className={styles.youtube}>{content}</div>;
};

const getVideoUrl = (videoId: string) =>
  `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0&autoplay=1`;

const getThumbnailUrl = (videoId: string) =>
  `https://i.ytimg.com/vi/${videoId}/sd2.jpg`;
