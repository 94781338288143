import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';
import { useBooleanState } from '../../../util/hooks';

import styles from './Menu.module.scss';

type Props = {
  isScrolled: boolean;
};

export const Menu = (props: Props) => {
  const { value: isOpen, toggle: toggleOpen } = useBooleanState(false);

  return (
    <div
      className={cx(styles.menu, {
        [styles.menuOpen]: isOpen,
        [styles.headerSticked]: props.isScrolled,
      })}
    >
      <MenuIcon
        className={cx(styles.menuIcon, { [styles.openMenuIcon]: isOpen })}
        onClick={toggleOpen}
      />
      <div
        className={cx(styles.menuItems, {
          [styles.openMenuItems]: isOpen,
          [styles.closeMenuItems]: !isOpen,
        })}
      >
        {links.map(({ to, label }) => (
          <NavLink
            key={to}
            to={to}
            className={({ isActive }) =>
              cx(styles.navLink, { [styles.navLinkActive]: isActive })
            }
            onClick={toggleOpen}
          >
            {label}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

interface Link {
  to: string;
  label: string;
}

const links: Link[] = [
  { to: '/', label: 'video' },
  { to: '/tour/', label: 'tour' },
  { to: '/bio/', label: 'bio' },
  // { to: "/contact", label: "contact" },
];
