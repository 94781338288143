import React from 'react';

import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram.svg';
import { ReactComponent as SoundcloudIcon } from '../../../assets/icons/soundcloud.svg';
import { ReactComponent as SpotifyIcon } from '../../../assets/icons/spotify.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/youtube.svg';
import { ReactComponent as BandCampIcon } from '../../../assets/icons/bandcamp.svg';

import styles from './Links.module.scss';

export const Links = () => {
  return (
    <div className={styles.container}>
      <Link
        Icon={SpotifyIcon}
        link="https://open.spotify.com/artist/7uDQe6QRLotltubAagAQNY?si=BdATD5aKS-aBnZvrSYxUwg"
      />
      <Link Icon={SoundcloudIcon} link="https://soundcloud.com/inneruu" />
      <Link
        Icon={YoutubeIcon}
        link="https://www.youtube.com/channel/UCaEb4GCqfyvscn57axRQlNw"
      />
      <Link Icon={InstagramIcon} link="https://www.instagram.com/inneruu" />
      <Link Icon={FacebookIcon} link="https://www.facebook.com/inneruu" />
      <Link
        Icon={BandCampIcon}
        link="https://inneruu.bandcamp.com/releases"
        size={70}
      />
    </div>
  );
};

interface LinkProps {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  link: string;
  size?: number;
}

const Link = ({ Icon, link, size }: LinkProps) => (
  <a href={link} target="_blank" rel="noreferrer">
    <Icon className={styles.icon} style={{ width: `${size}px` }} />
  </a>
);
