export type TourEntry = {
  eventName: string;
  begin: string;
  location?: string;
  city?: string;
  eventLink?: string;
};

const tours: TourEntry[] = [
  {
    eventName: "Nacht van de Zintuigen",
    begin: "2024-05-01 20:00",
    city: "Lakenhal, Leiden",
    eventLink: "https://www.lakenhal.nl/nl/verhaal/nacht-van-de-zintuigen",
  },
  {
    eventName: "Zaal 3",
    begin: "2024-05-13 20:00",
    city: "Den Haag",
    eventLink: "https://www.hnt.nl/voorstellingen/8768/inneruu/inneruu-night",
  },
  {
    eventName: "Museumnacht",
    begin: "2023-10-07 20:30",
    city: "Den Haag",
    eventLink: "https://museumnachtdenhaag.nl/",
  },
  {
    eventName: "Nacht van Ontdekkingen",
    begin: "2023-09-16 20:00",
    city: "Leiden",
    eventLink: "https://www.nachtvanontdekkingen.nl/programma-indiv/inneruu",
  },
  {
    eventName: "Reversed",
    begin: "2022-10-06 20:00",
    location: "Secret Location",
  },
  {
    eventName: "Album Release Night II",
    begin: "2022-09-30 19:00",
    city: "Den Haag",
    location: "Secret Location",
  },
  {
    eventName: "Album Release Night",
    begin: "2022-09-09 19:00",
    city: "Den Haag",
    location: "Secret Location",
  },
  {
    eventName: "Nacht van de Ontdekkingen",
    eventLink: "https://www.nachtvanontdekkingen.nl/portfolio/inneruu",
    begin: "2022-09-17 19:00",
    city: "Leiden",
    location: "Oranjerie, Hortus Botanicus",
  },
  {
    eventName: "GÂRDEN stream",
    eventLink: "https://garden.stream/event/inneruu/",
    begin: "2022-09-22 21:00",
    city: "",
  },
  {
    eventName: "Museumnacht Den Haag",
    eventLink: "https://museumnachtdenhaag.nl",
    begin: "2022-10-08 20:00",
    city: "Den Haag",
    location: "Secret Location",
  },
  {
    eventName: "Amuse Festival 2022",
    eventLink: "https://nieuwplaatz.nl/amuse-festival",
    begin: "2022-04-30",
    city: "Leiden",
    location: "Nieuwplaatz",
  },
  {
    eventName: "Amuse Festival 2022",
    eventLink: "https://nieuwplaatz.nl/amuse-festival",
    begin: "2022-04-29",
    city: "Leiden",
    location: "Nieuwplaatz",
  },
  {
    eventName: "Club 3voor12 Leiden",
    eventLink: "https://fb.me/e/eQ9q3D0KV",
    begin: "2022-03-31 20:00",
    city: "Leiden",
    location: "Gebr. de Nobel",
  },
  {
    eventName: "Imagine Intuition",
    begin: "2022-12-09 19:00",
    city: "Leiden",
    location: "Lakenhal",
  },
  {
    eventName: "Newgang Launch",
    begin: "2023-01-21 23:00",
    city: "Amsterdam",
    location: "Sissi's Expo-Resto",
  },
  {
    eventName: "inneruu night III",
    begin: "2023-04-21 19:00",
    city: "Den Haag",
    location: "Secret Location",
  },
  {
    eventName: "Amuse Festival",
    begin: "2023-04-28 20:00",
    city: "Nieuwplaatz",
    location: "Leiden",
  },
  {
    eventName: "Amuse Festival",
    begin: "2023-04-29 20:00",
    city: "Nieuwplaatz",
    location: "Leiden",
  },
];

export default tours;
