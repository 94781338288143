import React from 'react';
import cx from 'classnames';

import styles from './Bio.module.scss';
import { Page } from '../Page/Page';

export const Bio = () => {
  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.bioWrapper}>
          <p>
            Since 2022 a portal opened to the organic electronic world
            {emphasised(' inneruu')}.
          </p>
          <p>
            {emphasised('Myra-ida')} van der Veen, {emphasised('Lennard')} van
            der Valk, {emphasised('Michiel ')}
            de Haan and {emphasised('Wouter')} Mol focus on the entire
            experience.
          </p>
          <p>
            With visual atmospheres, fresh smells and a broad variety of
            different instruments, they create a space where you can playfully
            join in and wonder.
          </p>
          <div className={styles.pressContainer}>
            <PressQuote
              quote="Een amuse die alle zintuigen prikkelt"
              source="Amuse Festival"
            />
            <PressQuote
              quote="Noorderlicht straalt op ontdekkingsreizigers van een nieuwe wereld"
              source="3voor12 Leiden"
              link="https://3voor12.vpro.nl/lokaal/leiden/artikelen/overzicht/2022/Maart/Noorderlicht-straalt-op-ontdekkingsreizigers-van-een-nieuwe-wereld-.html"
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

const emphasised = (text: string) => (
  <span className={styles.bandName}>{text}</span>
);

interface PressQuoteProps {
  quote: string;
  source: string;
  link?: string;
}

const PressQuote = ({ quote, source, link }: PressQuoteProps) => (
  <div className={styles.pressQuote}>
    "{quote}"
    <br />
    <a className={cx({ [styles.quoteWithLink]: !!link })} href={link}>
      <span className={styles.source}>{source}</span>
    </a>
  </div>
);
