import { Route, Routes } from 'react-router-dom';
import { Bio } from '../pages/Bio/Bio';

import { Music } from '../pages/Music/Music';
import { Tour } from '../pages/Tour/Tour';

import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.container}>
      <Routes>
        <Route path="/" element={<Music />} />
        <Route path="/tour/" element={<Tour />} />
        <Route path="/bio/" element={<Bio />} />
      </Routes>
    </div>
  );
}

export default App;
