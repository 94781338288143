import React from "react";
import cx from "classnames";

import tours, { TourEntry } from "./tours";

import { Page } from "../Page/Page";

import styles from "./Tour.module.scss";

export const Tour = () => {
  const now = new Date();
  const upcomingTours = tours
    .map(mapTourBegin)
    .filter((tour) => tour.date > now)
    .sort(sortTours(true));

  const pastTours = tours
    .map(mapTourBegin)
    .filter((tour) => tour.date < now)
    .sort(sortTours(false));

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.tours}>
          <div className={styles.column}>
            <div className={styles.header}>upcoming</div>
            <div className={styles.list}>
              {upcomingTours.map((tour) => (
                <TourComponent tour={tour} showLink={true} />
              ))}
              {upcomingTours.length === 0 && upcomingPlaceholder}
            </div>
            <div className={styles.header}>past</div>
            <div className={styles.list}>
              {pastTours.map((tour) => (
                <TourComponent tour={tour} showLink={false} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

const upcomingPlaceholder = (
  <div className={styles.tourEntry}>
    Er zijn op dit moment geen tours gepland
  </div>
);

const getTourDate = (tourDate: string): TourDate => {
  const [date, time] = tourDate.split(" ");
  const [year, month, day] = date.split("-").map((x) => parseInt(x, 10));
  if (!time) {
    return { date: new Date(year, month - 1, day), hasTime: false };
  }
  const [hour, minute] = time.split(":").map((x) => parseInt(x, 10));
  return { date: new Date(year, month - 1, day, hour, minute), hasTime: true };
};

type TourDate = {
  date: Date;
  hasTime: boolean;
};

type TourEntryWithDate = Omit<TourEntry, "begin"> & TourDate;

const mapTourBegin = (tour: TourEntry): TourEntryWithDate => ({
  ...tour,
  ...getTourDate(tour.begin),
});
const sortTours = (ascending: boolean) => {
  return (a: TourEntryWithDate, b: TourEntryWithDate) => {
    const ba = a.date.getTime();
    const bb = b.date.getTime();
    return ascending ? ba - bb : bb - ba;
  };
};

const getDateFormat = (date: Date, hasTime: boolean) => {
  const day = padWithZeroPrefix(date.getDate(), 2);
  const month = padWithZeroPrefix(date.getMonth() + 1, 2);
  const year = date.getFullYear();

  let dateString = `${day}-${month}-${year}`;

  if (hasTime) {
    const hours = padWithZeroPrefix(date.getHours(), 2);
    const minutes = padWithZeroPrefix(date.getMinutes(), 2);
    dateString += ` ${hours}:${minutes}`;
  }

  return dateString;
};

const padWithZeroPrefix = (str: string | number, desiredLength: number) =>
  `${"0".repeat(desiredLength - str.toString().length)}${str}`;

type TourComponentProps = {
  tour: TourEntryWithDate;
  showLink: boolean;
};

const TourComponent = ({ tour, showLink }: TourComponentProps) => {
  const locationSeparator = tour.location && tour.city ? ", " : "";

  return (
    <div key={tour.date.getTime()} className={styles.tourEntry}>
      <div className={cx(styles.tourEntryLine, styles.tourEntryName)}>
        {tour.eventName}
      </div>
      <div className={styles.tourEntryLine}>
        {getDateFormat(tour.date, tour.hasTime)}
      </div>
      <div className={styles.tourEntryLine}>
        {tour.location}
        {locationSeparator}
        {tour.city}
      </div>
      {showLink && tour.eventLink && (
        <a
          target="_blank"
          rel="noreferrer"
          className={cx(styles.eventLink, styles.tourEntryLine)}
          href={tour.eventLink}
        >
          link
        </a>
      )}
    </div>
  );
};
