import React from "react";

import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as DropboxIcon } from "../../assets/icons/dropbox.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/email.svg";

import styles from "./Contact.module.scss";

export const Contact = () => {
  const presskitLink = (
    <a
      className={styles.link}
      target="_blank"
      href="https://www.dropbox.com/sh/nqzewibjxnus218/AACiLp-_nPWKMhoq0zE5TuODa?dl=0"
      rel="noreferrer"
    >
      presskit
    </a>
  );
  return (
    <div className={styles.container}>
      <div className={styles.contact}>
        <Row Icon={DropboxIcon} text={presskitLink} />
        <Row Icon={MailIcon} text="band@inneruu.com" />
        <Row Icon={PhoneIcon} text="+31 6 1558 6206" />
      </div>
    </div>
  );
};

interface RowProps {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  text: React.ReactNode;
}
const Row = ({ Icon, text }: RowProps) => (
  <div className={styles.row}>
    <Icon className={styles.icon} />
    <span className={styles.text}>{text}</span>
  </div>
);
