import React, { PropsWithChildren } from 'react';

import styles from './Page.module.scss';

import { Contact } from '../Contact/Contact';
import { Links } from './Links/Links';
import { Menu } from './Menu/Menu';
import { useBooleanState } from '../../util/hooks';

interface Props {}

export const Page = (props: PropsWithChildren<Props>) => {
  const { children } = props;

  const { value: isScrolled, setValue: setIsScrolled } = useBooleanState(false);

  return (
    <div className={styles.pageContainer}>
      <Menu isScrolled={isScrolled} />
      <div
        className={styles.scrollableContent}
        onScroll={(e) => {
          setIsScrolled((e.target as HTMLDivElement).scrollTop > 0);
        }}
      >
        <Links />
        <div className={styles.content}>{children}</div>
        <Contact />
      </div>
    </div>
  );
};
